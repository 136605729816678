<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="Cari" label-for="basicInput">
            <b-form-input
              id="basicInput"
              placeholder="Search"
              v-model="search"
            />
          </b-form-group>
        </b-col>
        
        

        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="id" label-for="id">
            <b-form-input
              id="id"
              placeholder="100"
              v-model="id"
            />
          </b-form-group>
        </b-col>
       
        
        <b-col lg="3" md="3" class="mb-1">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
        <b-col lg="2" md="2" class="mb-1">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="primary"
            class="mt-0 mt-md-2"
            @click="createJurusan()"
          >
            <feather-icon icon="EditIcon" class="mr-25" />
            <span>Input New</span>
          </b-button>
        </b-col>
      </b-row>

      
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      
    <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="updateJurusan(data.item)">
            <feather-icon icon="EditIcon" size="16" />
            <span class="align-middle ml-50">Update</span>
          </b-dropdown-item>
         
          <b-dropdown-item @click="deleteJurusan(data.item)">
            <feather-icon icon="TrashIcon" size="16" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        
        
        </b-dropdown>
      </template>
     
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    

    <b-modal id="modal-create-jurusan" hide-footer no-close-on-backdrop>
      <template #modal-title> Create Jurusan </template>
      <div class="d-block text-center">
        <!-- <p>Apakah anda yakin? Jika ya silahkan input deskripsi.</p> -->
      </div>
     
          <!-- basic -->
        <b-col md="12" xl="12" class="mb-1">
          <b-form-group label="Universitas"  label-for="vue-select">
            <v-select
              id="vue-select"
              label="nama_universitas"
              v-model="universitas"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="universitas_list"
            />
          </b-form-group>
        </b-col>
        <b-col md="12" xl="12" class="mb-1">
          <b-form-group label="Fakultas"   label-for="vue-select">
            <v-select
              id="vue-select"
              label="nama_fakultas"
              v-model="fakultas"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="fakultas_list"
            />
          </b-form-group>
        </b-col>
          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Name" label-for="basicInput">
              <b-form-input
                id="basicInput"
                placeholder="Name"
                v-model="name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Sequence" label-for="thevalue">
              <b-form-input
                id="thevalue"
                type="number"
                placeholder="Sequence"
                v-model="value"
              />
            </b-form-group>
          </b-col>


      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="submitCreate()"
      >
        <feather-icon icon="SendIcon" /> Submit
      </b-button>
    </b-modal>
    <b-modal id="modal-update-jurusan" hide-footer no-close-on-backdrop>
      <template #modal-title> Update Jurusan </template>
      <div class="d-block text-center">
        <!-- <p>Apakah anda yakin? Jika ya silahkan input deskripsi.</p> -->
      </div>
     
          <!-- basic -->
               <!-- basic -->
        <b-col md="12" xl="12" class="mb-1">
          <b-form-group label="Universitas"  label-for="vue-select">
            <v-select
              id="vue-select"
              label="nama_universitas"
              v-model="universitas"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="universitas_list"
            />
          </b-form-group>
        </b-col>
        <b-col md="12" xl="12" class="mb-1">
          <b-form-group label="Fakultas"   label-for="vue-select">
            <v-select
              id="vue-select"
              label="nama_fakultas"
              v-model="fakultas"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="fakultas_list"
            />
          </b-form-group>
        </b-col>
          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Name" label-for="basicInput">
              <b-form-input
                id="basicInput"
                placeholder="Name"
                v-model="name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" xl="12" class="mb-1">
            <!-- basic -->
            <b-form-group label="Sequence" label-for="thevalue">
              <b-form-input
                id="thevalue"
                type="number"
                placeholder="Sequence"
                v-model="value"
              />
            </b-form-group>
          </b-col>



      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="submitUpdate()"
      >
        <feather-icon icon="SendIcon" /> Submit
      </b-button>
    </b-modal>
    <b-modal id="modal-delete-jurusan" hide-footer>
      <template #modal-title> Update Jurusan </template>
      <div class="d-block text-center">
        <p>Apakah anda yakin?</p>
      </div>
     
          <!-- basic -->
          
          


      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="submitDelete()"
      >
        <feather-icon icon="SendIcon" /> Submit
      </b-button>
    </b-modal>
  </b-card-code>
  
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BPagination, BFormTextarea,
  BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BRow, BCol, BModal
} from 'bootstrap-vue'
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ['multi', 'single', 'range'],
      fields: ['id','nama_jurusan', 'urutan',{ key: 'universitas.nama_universitas', label: 'Universitas' },{ key: 'fakultas.nama_fakultas', label: 'Fakultas' }, 'created_at', 'action'],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      search: null,
      id: null,
      detailJurusan: null,
      name: null,
      value: null,
      universitas_list: [],
      universitas:{id:1, nama_universitas:"Universitas Indonesia"},
      fakultas_list: [],
      fakultas: { id: 3, nama_fakultas: "Kedokteran Gigi", akronim: "FKG" }
      

    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getJurusan()
      }
      else {
        this.currentPage = 1
      }

    },
    async getJurusan() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          id_jurusan: this.id,
          is_pagination:true
        }
        const data   = await this.encryptRequest(API.master.jurusan(form))
        this.data_table = data.data.data
        this.items = data.data.data
        this.rows = data.data.total

        this.handleMsgSuccess('Berhasil tampilkan data log')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    createJurusan() {
      try {
        this.getUniversitas()
        this.getFakultas()
      } catch (error) {
        
      } finally {
        this.$bvModal.show("modal-create-jurusan")
      }
     
    },
    async submitCreate() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      try {
        
        var input = { 
          nama_jurusan: this.name, 
          urutan: this.value,
          id_fakultas: this.fakultas.id,
          id_universitas:this.universitas.id
        }
        const {}  = await this.encryptRequest(API.master.create_jurusan(input))
        this.handleMsgSuccess('Berhasil Create Jurusan')
        this.getJurusan()
        this.$bvModal.hide("modal-create-jurusan")
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      }
      finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }


    },
    async updateJurusan(item) {
      this.detailJurusan = item
      this.name = item.nama_jurusan
      this.value = item.urutan
      console.log(this.detailJurusan)
      try {
        await this.getMaster()
       
      } catch (error) {
        
      } finally {
        this.universitas = this.universitas_list.find(univ => univ.id === item.universitas.id);
        this.fakultas = this.fakultas_list.find(fak => fak.id === item.fakultas.id);
        console.log(this.fakultas)
        console.log(this.universitas)
        
      }

      this.$bvModal.show("modal-update-jurusan")
    

    },
    deleteJurusan(item) {
      this.detailJurusan = item
      console.log(this.detailJurusan)
      this.$bvModal.show("modal-delete-jurusan")

    },
    async submitUpdate() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const form = {
          nama_jurusan: this.name, 
          urutan: this.value,
          id: this.detailJurusan.id,
          id_fakultas: this.fakultas.id,
          id_universitas:this.universitas.id
        }
        const data   = await this.encryptRequest(API.master.update_jurusan(form))

        this.handleMsgSuccess('Berhasil update jurusan')
        this.$bvModal.hide("modal-update-jurusan")
        this.getJurusan()
      } catch (error) {
        console.log(error)
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async submitDelete() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const form = {
          id:this.detailJurusan.id
        }
        const data   = await this.encryptRequest(API.master.delete_jurusan(form))

        this.handleMsgSuccess('Berhasil delete jurusan')
        this.$bvModal.hide("modal-delete-jurusan")
        this.getJurusan()
      } catch (error) {
        console.log(error)
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },

    async getUniversitas() {
        try {
          const form = {
            get_all:true
          }
          const data   = await this.encryptRequest(API.master.universitas(form))
          this.universitas_list = data.data
        } catch (error) {
          console.log(error)
        } finally {
         
        }
    },
    async getFakultas() {
        try {
          const form = {
            get_all:true
          }
          const data   = await this.encryptRequest(API.master.fakultas(form))
          this.fakultas_list = data.data
          console.log(this.fakultas_list)
        } catch (error) {
          console.log(error)
        } finally {
         
        }
      
      
    },
    async getMaster() {
     
      this.getUniversitas()
      this.getFakultas()
    }



  },
  mounted() {
    this.getJurusan()
    
  },
  watch: {
    currentPage() {
      this.getJurusan()
    },

  }
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
